import React from 'react';
//import "./NavbarStyles.css"
//import logo from "../../images/logo_home_legal_protec_7x4.jpeg"
import SloganImage from '../components/SeccSloganImage/SloganImage';
import QuienesSomos from '../components/QuienesSomos/QuienSomos';
import Footer from '../components/Footer/Footer';
import TiposPoliza from '../components/TiposPoliza/TiposPoliza';
import PreciosTable from '../components/Precios/PreciosTable';
import Documentacion from '../components/Documentacion/Documentacion';


const Home = () => {
return (
	<>
		<SloganImage/>
		<QuienesSomos/>
		<TiposPoliza/>
		<PreciosTable/>
		<Documentacion/>
		<Footer/>
		

	</>
);
};

export default Home;
