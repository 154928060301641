import React, { Component } from "react";
import "./DocumentacionStyle.css"
//import logo from "../../images/logo_home_legal_protec_7x4.jpeg"

class Documentacion extends Component {

    

    render() {
        return (
                <>
                    <section id="Plan" className="Documentation">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12">
                                    <div className="ChoosePlan__title">
                                        <h2 id="chooseAPlanH2" className="elementor-heading-title elementor-size-default">Documentación requerida</h2>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-4">
                                    <div className="ChoosePlan__card">
                                        <div id="choosePlanButton_1" className="ChoosePlan__card_in" >
                                            <h3 id="individualClassesH3">Persona Física</h3>
                                            <h4 id="justYouAH4">Inquilino y Obligado Solidario</h4>
                                            <ul className="ChoosePlan__lu">
                                                <li>
                                                    <div className="ChoosePlan__lu_li_div">
                                                        <i aria-hidden="true" className="fas fa-check-circle"></i>
                                                        <span id="chooseYourTS">Identificación oficial (INE, Pasaporte o Cédula Profesional)</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="ChoosePlan__lu_li_div">
                                                        <i aria-hidden="true" className="fas fa-check-circle"></i>
                                                        <span id="privateClassFS">Recibos de Nómina o Estados de Cuenta (periodo de los últimos 3 meses)</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="ChoosePlan__lu_li_div">
                                                        <i aria-hidden="true" class="fas fa-check-circle"></i>
                                                        <span id="firstClassFS">Comprobante de domicilio actual.</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i aria-hidden="true" class="fas fa-check-circle"></i>
                                                        <span id="firstClassFS">Solicitud de arrendamiento debidamente requisitada.</span>
                                                    </div>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xs-12 col-sm-4">
                                    <div className="ChoosePlan__card">
                                        <div id="choosePlanButton_1" className="ChoosePlan__card_in" >
                                            <h3 id="individualClassesH3">Persona Moral</h3>
                                            <h4 id="justYouAH4">Inquilino y Obligado solidario</h4>
                                            <ul className="ChoosePlan__lu">
                                                <li>
                                                    <div className="ChoosePlan__lu_li_div">
                                                        <i aria-hidden="true" className="fas fa-check-circle"></i>
                                                        <span id="chooseYourTS">Identificación oficial (INE, Pasaporte o Cédula Profesional) Representante legal.</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="ChoosePlan__lu_li_div">
                                                        <i aria-hidden="true" className="fas fa-check-circle"></i>
                                                        <span id="privateClassFS">Copia Acta Constitutiva.</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i aria-hidden="true" class="fas fa-check-circle"></i>
                                                        <span id="firstClassFS">Documento que lo acredite como Representante Legal.</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="ChoosePlan__lu_li_div">
                                                        <i aria-hidden="true" class="fas fa-check-circle"></i>
                                                        <span id="firstClassFS">Ultima declaración fiscal.</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i aria-hidden="true" class="fas fa-check-circle"></i>
                                                        <span id="firstClassFS">Solicitud de arrendamiento debidamente requisitada.</span>
                                                    </div>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xs-12 col-sm-4">
                                    <div className="ChoosePlan__card">
                                        <div id="choosePlanButton_1" className="ChoosePlan__card_in" >
                                            <h3 id="individualClassesH3">Propietario</h3>
                                            <h4 id="justYouAH4">&nbsp;</h4>
                                            <ul className="ChoosePlan__lu">
                                                <li>
                                                    <div className="ChoosePlan__lu_li_div">
                                                        <i aria-hidden="true" className="fas fa-check-circle"></i>
                                                        <span id="chooseYourTS">Copia de las primeras hojas de la escritura donde identifique la dirección de la propiedad y su situación legal de propietario.</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="ChoosePlan__lu_li_div">
                                                        <i aria-hidden="true" className="fas fa-check-circle"></i>
                                                        <span id="privateClassFS">Identificación oficial (INE, Pasaporte o Cédula Profesional)</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i aria-hidden="true" class="fas fa-check-circle"></i>
                                                        <span id="firstClassFS">Solicitud debidamente requisitada.</span>
                                                    </div>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                    </section>
                </>
            );
        }
}

export default Documentacion;
