import React, { Component } from "react";
import "./SloganImageStyles.css"
//import logo from "../../images/logo_home_legal_protec_7x4.jpeg"
//<h1>Legal Protection</h1>

class SloganImage extends Component {

    

    render() {
        return (
                <section className="PrincipalSpace">
                    <div className="row">
                        <div>
                            
                        </div>
                    </div>
                </section>
            );
        }
}

export default SloganImage;
