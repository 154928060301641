import React from 'react';
//import Navbar from './components/Navbar';
import MyNavbar from './components/Navbar/MyNavbar';
import { BrowserRouter as Router, Routes, Route}
	from 'react-router-dom';
import "./index.css"
import Home from './pages';
import Products from './pages/products';
import About from './pages/about';
import Contact from './pages/contact';

//<Route exact path='/' element={<Home />} />
//<Route path='/about' element={<About/>} />
//<Navbar />

function App() {
return (
	<Router>
	<MyNavbar/>
	
	<Routes>	
		<Route exact path='/' element={<Home />} />
		<Route path='/products' element={<Products/>} />
		<Route path='/about' element={<About/>} />
		<Route path='/contact' element={<Contact/>} />
		
	</Routes>
	</Router>
);
}

export default App;