import React, { Component } from "react";
import "./FooterStyle.css"
import logo from "../../images/logo_home_legal_protec_7x4.jpeg"


class Footer extends Component {

    

    // Function will execute on click of button
    onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('AvisoPrivacidadHLP.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'AvisoPrivacidadHLP.pdf';
                alink.click();
            })
        })
        
    }

    render() {
        return (
            <footer class="Under">
                <div class="container">
                    <div className="row">
                        <div class="col-xs-12 col-sm-4">
                            <ul class="Under__Left">
                                <li><a href="/">Inicio</a></li>
                                <li>
                                    <button onClick={this.onButtonClick}>
                                        Aviso de Privacidad
                                    </button>
                                </li>
                                <li><a href="mailto:atencion@vitasicuramx.com?subject=Nuevo%20mensaje%20de%20consulta">Contactanos</a></li>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-sm-4 Under__Center">
                            <a href="/">
                                <img className="img-responsive" src={logo}/>
                            </a>
                        </div>
                        <div class="col-xs-12 col-sm-4 Under__Right">
                            <h4>SOCIAL</h4>
                            <li><a href="https://instagram.com/homelag?igshid=ayblzbdjlzy2" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="https://www.facebook.com/homelegal" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://wa.me/5215511920158" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/xxxxxxxxxxx" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        </div>
                    </div>
                </div>
            </footer>
            );
        }
}

export default Footer;
