import React from "react";

const About = () => {
return (
	<div>
	<h1>
		Acerca de nosotros.
	</h1>
	</div>
);
};

export default About;
