import React, { Component } from "react";
import "./NavbarStyles.css"
import logo from "../../images/logo_home_legal_protec_7x4.jpeg"
import logoWhats from "../../images/whatsapp.png"
import logoMail from "../../images/mail-icon.png"

class MyNavbar extends Component {
    
    constructor(props){
        super(props);

        var dataTemp = ['','','',''];
        let url = window.location + '';
        if(url.lastIndexOf('products') != (-1))
            dataTemp[1]='active';
        else if(url.lastIndexOf('about') != (-1))
            dataTemp[2]='active';
        else if(url.lastIndexOf('contact') != (-1))
            dataTemp[3]='active';
        else
            dataTemp[0]='active';

        var dataLinksTemp = ['/','#quienessomos', '#tipopoliza','#precios_secc','mailto:atencion@vitasicuramx.com?subject=Nuevo%20mensaje%20de%20consulta'];
        if(url.lastIndexOf('contact') != (-1)){
            dataLinksTemp[1]='/#quienessomos';
            dataLinksTemp[2]='/#tipopoliza';
            dataLinksTemp[3]='/#precios_secc';
            
        }

        this.state = {   clicked: false,
            activeLink: dataTemp,
            responseLink: dataLinksTemp
        };
        
    }
    

    handleClick = () => {
        this.setState({clicked: !this.state.clicked})
    }

    render() {
        return (
            <>
                <div>
                    
                    <section>
                        <nav>
                            <a href="/">
                                <img src={logo} className="logoicon" alt="Logo" />
                            </a>
                            <a href="https://wa.me/5215511920158" alt="Whats app">
                                <img src={logoWhats} className="logoicon_mini" alt="Logo" />
                            </a>
                            <a href="mailto:atencion@vitasicuramx.com?subject=Nuevo%20mensaje%20de%20consulta" alt="Whats app">
                                <img src={logoMail} className="logoicon_mini" alt="Logo" />
                            </a>
                            <div>
                                <ul id="navbar" className={this.state.clicked ? "#navbar active" : "#navbar"}>
                                    <li><a className={this.state.activeLink[0]} href={this.state.responseLink[0]}>Inicio</a></li>
                                    <li><a className={this.state.activeLink[1]} href={this.state.responseLink[1]}>¿Quiénes Somos?</a></li>
                                    <li><a className={this.state.activeLink[2]} href={this.state.responseLink[2]}>Tipos de Pólizas</a></li>
                                    <li><a className={this.state.activeLink[2]} href={this.state.responseLink[3]}>Costos</a></li>
                                    <li><a className={this.state.activeLink[3]} href={this.state.responseLink[4]}>Contactanos</a></li>
                                </ul>
                            </div>

                            <div id="mobile" onClick={this.handleClick}>
                                <i id="bar" className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}>

                                </i>
                            </div>
                        </nav>
                    </section>
                </div>
            </>
        );
    }
};
    
export default MyNavbar;
    