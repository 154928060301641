import React, { Component } from "react";
import "./TiposPolizaStyle.css"
import imagen1 from "../../images/poliza-1.png"
import imagen2 from "../../images/poliza-2.png"
import imagen3 from "../../images/poliza-3.png"

class TiposPoliza extends Component {

    render() {
        return (
            <section id="tipopoliza" className="TiposPolizaSecc">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="HowLearn__title">
                                <h2 id="howToLearnH4">Tipos de <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pólizas</strong></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-4 HowLearn__card_row">
                            <div id="cardHLButton_1" className="HowLearn__card">
                                <img src={imagen1} alt="Póliza Elemental"></img>
                                <h4 id="choosePlanH4">1. Póliza Elemental</h4>
                                <p id="learnAtYourP">Incluye: investigación a nivel nacional, la cobranza extrajudicial de rentas, asesoría para para la resolución de controversias propietario – inquilino, desalojo de la propiedad en caso de ser requerido.</p>
                                
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 HowLearn__card_row">
                            <div id="cardHLButton_2" className="HowLearn__card">
                                <img src={imagen2} alt="Póliza Elemental Plus"></img>
                                <h4 id="scheduleYourlH4">2. Póliza Elemental Plus</h4>
                                <p id="wheneverYouWP">Incluye: investigación a nivel nacional, la cobranza extrajudicial de rentas, apoyo en resolución de controversias propietario – inquilino con atención personalizada, desalojo de la propiedad en caso de ser requerido, aceptación de inquilinos sin obligado solidario o que el obligado vive en el mismo domicilio, la póliza sigue activa 30 días naturales más después de su terminación con la finalidad de apoyar en la renovación y cubrir los intereses de las partes.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 HowLearn__card_row">
                            <div id="cardHLButton_3" className="HowLearn__card">
                                <img src={imagen3} alt="Póliza Protección Total"></img>
                                <h4 id="attendYourCH4">3. Póliza Protección Total</h4>
                                <p id="joinYourLP">Incluye: investigación a nivel nacional, la cobranza extrajudicial de rentas, apoyo en resolución de controversias propietario – inquilino con atención personalizada, desalojo de la propiedad en caso de ser requerido, recuperación de rentas vencidas (*requiere aval con propiedad libre de gravamen).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            );
        }
}

export default TiposPoliza;
