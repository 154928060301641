import React, { Component } from "react";
import "./QuienSomosStyle.css"
//import logo from "../../images/logo_home_legal_protec_7x4.jpeg"
import imagen1 from "../../images/banner_con_logo.jpg"
import imagen2 from "../../images/esta_pensando_rentar_su_propiedad.jpg"

class QuienesSomos extends Component {

    

    render() {
        return (
                <>
                    <section id="quienessomos" className="container">
                        <div className="row QuienesSomos">
                            <div class="col-xs-12 col-lg-1">&nbsp;</div>
                            <div class="col-xs-12 col-lg-3">
                                <h2>¿Quiénes somos?</h2>
                            </div>
                            <div class="col-xs-12 col-lg-7 regilla_centrada">
                                <img src={imagen1} className="imageQuienesSomos" alt="Logo" />
                            </div>
                            <div class="col-xs-12 col-lg-1">&nbsp;</div>

                            
                            
                            <div class="col-1">&nbsp;</div>
                            <div class="col-10">
                                <p>Somos una empresa dedicada a los servicios de Protección Legal Inmobiliaria que se da a los propietarios de inmuebles y a las personas que deciden rentar dichas propiedades, todo bajo el marco legal aplicable en el estado en que se encuentra la propiedad, tomando siempre en cuenta el bienestar de las partes y buscando el mejor camino para la resolución de conflictos.</p>
                            </div>
                            <div class="col-1">&nbsp;</div>

                            <div class="col-1">&nbsp;</div>
                            <div class="col-10">
                                <p>A los Propietarios otorgamos los servicios de cobranza extrajudicial de sus rentas, así como la asesoría legal en todo momento en cuanto a controversias con sus inquilinos y en caso de ser requerido el desalojo del inmueble arrendado hasta entregar la propiedad deshabitada.</p>
                            </div>
                            <div class="col-1">&nbsp;</div>

                            <div class="col-1">&nbsp;</div>
                            <div class="col-10">
                                <p>A los inquilinos les proporcionamos la tranquilidad que les brinda la ley en cuanto a que la propiedad cuente con las condiciones necesarias para ser habitada, resolución de conflictos con los propietarios en caso de existir controversia en cuanto a mantenimientos o arreglos necesarios, todo bajo el marco de la ley.</p>
                            </div>
                            <div class="col-1">&nbsp;</div>

                            <div class="col-1">&nbsp;</div>
                            <div class="col-10">
                                <p>Para ambas partes somos garantes de que se cumplan las condiciones de costo, tiempo y cláusulas establecidas por las partes en el contrato de arrendamiento.</p>
                            </div>
                            <div class="col-1">&nbsp;</div>

                            
                        </div>
                    </section>

                    <section id="quienessomos" className="container">
                    <div className="row QuienesSomos">
                        <div class="col-xs-12 col-lg-1">&nbsp;</div>
                        <div class="col-xs-12 col-lg-3">
                            <h2>¿Está pensando rentar su propiedad?</h2>
                        </div>
                        
                        <div class="col-xs-12 col-lg-7 regilla_centrada">
                            <img src={imagen2} className="imageQuienesSomos" alt="Logo" />
                        </div>
                        <div class="col-xs-12 col-lg-1">&nbsp;</div>

                        <div class="col-1">&nbsp;</div>
                        <div class="col-10">
                            <p>La póliza jurídica le otorga de manera inicial una investigación de su prospecto a inquilino, en el Buró Legal a nivel nacional, en el cuál evaluaremos si ha tenido algún tema legal en los últimos años, además de una investigación por parte de nuestro equipo en cuanto a referencias, situación laboral y solvencia, otorgando al propietario un resumen de viabilidad del o los interesados.</p>
                        </div>
                        <div class="col-1">&nbsp;</div>

                        <div class="col-1">&nbsp;</div>
                        <div class="col-10">
                            <p>Realizamos la generación del contrato de arrendamiento de acuerdo con los códigos y normas vigentes en el estado en el que se encuentra la propiedad, le damos un servicio integral a través de nuestros medios de comunicación con asesoría para el propietario y el inquilino sin costo, la cobranza extrajudicial y el apoyo en controversias que llegara a existir.</p>
                        </div>
                        <div class="col-1">&nbsp;</div>

                        <div class="col-1">&nbsp;</div>
                        <div class="col-10">
                            <p>Usted puede estar tranquilo de que su propiedad esta cubierta en caso de cualquier situación en la que se requiera asistencia legal, así mismo otorgamos al inquilino la tranquilidad de que durante su estancia se cumplirán los acuerdos a los que ambos llegaron desde un inicio.</p>
                        </div>
                        <div class="col-1">&nbsp;</div>

                        
                    </div>
                    </section>
                </>
            );
        }
}

export default QuienesSomos;
